.header {
  background-color: $beige;
  padding: 16px responsive(32, 248);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 2px solid $black;
  position: fixed;
  width: 100%;
  z-index: 10;
  &-link {
    font-size: 30px;
    font-weight: 700;
  }
  .menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    li {
      margin: 0 24px;
      a {
        font-weight: 700;
        opacity: 1;
        transition: opacity .4s ease;
        &:hover {
          opacity: .6;
        }
      }
    }
  }

  .search-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    .search {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      right: 0;
      width: 0;
      overflow: hidden;
      transition:  width .4s ease;
      [type="text"] {
        background: $beige;
        border-bottom: 2px solid $black;
        padding: 5px 10px;
        margin: 0 20px 0 0;
        width: calc(100% - 50px);
      }
      &.open {
        width: 60vw;
      }
    }
    .btn-submit, .btn-search {
      background-image: url($img + 'search.svg');
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      width: 30px;
      height: 30px;
    }
    .btn-search {
      opacity: 1;
      transition: opacity .4s ease;
      &:hover {
        opacity: .6;
      }
    }
  }

  .btn-menu {
    width: 30px;
    height: 20px;
    position: relative;
    &:before, &:after {
      content: '';
      position: absolute;
      height: 2px;
      width: 100%;
      left: 0;
      background: $black;
      transition: transform .4s ease, top .4s ease, left .4s ease;
      transform: translate(0%, 0%) rotate(0deg);
    }
    &:before {
      top: 0;
    }
    &:after {
      top: calc(100% - 2px);
    }
    span {
      display: block;
      width: 100%;
      position: absolute;
      height: 2px;
      background: $black;
      top: 50%;
      left: 50%;
      opacity: 1;
      transform: translate(-50%, -50%);
      transition: opacity .4s ease;
    }
    &.active {
      span {
        opacity: 0;
      }
      &:before, &:after {
        top: 50%;
        left: 50%;
      }
      &:before {
        transform: translate(-50%, -50%) rotate(45deg);
      }
      &:after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }
}

// Current link
.page:not(.home) {
  .menu {
    li {
      color: $gray;
      &.current-menu-item {
        color: $black;
      }
    }
  }
}

// Mobile menu btn
@media(max-width: $tablet) {
  .header {
    justify-content: flex-end;
    position: relative;
    &-link {
      position: absolute;
      left: responsive(32, 248);
    }
    .btn-menu {
      margin-left: 20px;
      &[hidden] {
        display: block;
      }
    }
    > div {
      width: calc(100% + 4px);
      position: absolute;
      top: 64px;
      right: -2px;
      .menu {
        background: $beige;
        overflow: hidden;
        flex-direction: column;
        align-items: flex-start;
        padding: 0 responsive(32, 248);
        border-right: 2px solid $black;
        border-left: 2px solid $black;
        max-height: 0px;
        transition: max-height .4s ease, padding .4s ease;
        &.open {
          max-height: 300px;
          padding: 24px responsive(32, 248);
          border-bottom: 2px solid $black;
        }
        li {
          margin: 0 0 24px;
        }
      }
    }
    .search-container {
      .search {
        &.open {
          width: 70vw;
        }
      }
    }
  }
}
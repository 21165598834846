.home {
  &-hero {
    position: relative;
    .logo {
      position: absolute;
      z-index: 1;
      top: 120px;
      right: responsive(32, 248);
      width: responsive(100, 279);
      img {
        width: 100%;
      }
    }
    .legend {
      position: absolute;
      z-index: 1;
      bottom: 10px;
      right: responsive(32, 248);
    }
    .link {
      position: absolute;
      z-index: 1;
      top: 50%;
      right: responsive(2, 20);
      transform: translateY(-50%) rotate(90deg);
      font-weight: 700;
    }
  }

  .hero-container {
    display: flex;
    position: relative;
    height: calc(100vh - 151px); 
    overflow: hidden;
    border-right: 2px solid $black;
    border-left: 2px solid $black;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
  }
  
  &-menu {
    display: flex;
    justify-content: space-between;
    background-color: $beige;
    padding: 0 responsive(32, 248);
    border: 2px solid $black;
    a {
      font-size: 20px;
      display: block;
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      opacity: 1;
      transition: opacity .4s ease;
      &:nth-child(2) {
        justify-content: flex-end;
        .icon-play {
          margin: 0 0 0 16px;
        }
      }
      &:hover {
        opacity: .8;
        .icon-play {
          &::after {
            transform: translate(-50%, -50%) scale(1.1);
          }
        }
      }
      .icon-play {
        display: block;
        height: 75px;
        width: 80px;
        position: relative;
        background-color: $bg;
        border-left: 2px solid $black;
        border-right: 2px solid $black;
        &:before, &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        &:before {
          width: 0; 
          height: 0; 
          border-top: 5px solid transparent;
          border-bottom: 5px solid transparent;
          border-left: 10px solid $black;
        }
        &:after {
          width: 36px; 
          height: 36px; 
          border-radius: 100%;
          border: 2px solid $black;
          transform: translate(-50%, -50%) scale(1);
          transition:  transform .4s ease;
        }
      }
    }
  }
}

@media(max-width: $tablet) {
  .home {
    &-hero {
      .logo {
        top: 60px;
      }
      .link {
        right: responsive(-10, 20);
      }
    }
    &-menu {
      a {
        font-size: 18px;
        .icon-play {
          width: 80px;
          height: 80px;
          &:after {
            width: 40px;
            height: 40px;
          }
        }
      }
    }
  }
}

@media(max-width: $mobile) {
  .home {
    &-menu {
      flex-direction: column;
      padding: 0;
      // border-left: none;
      border-right: none;
      a {
        width: 100%;
        padding-left: responsive(32, 248);
        &:nth-child(2) {
          justify-content: space-between;
          border-top: 2px solid $black;
        }
      }
    }
  }
}
$fonts: '../assets/fonts/';

[hidden] {
  display: none;
}

.wrap {
  padding: 72px responsive(24, 96) 0;
  @media(max-width: $tablet) {
    padding: 0 responsive(24, 96);
  }
}

.current {
  opacity: 1 !important;
}

// Font-face
@font-face {
  font-family: FrankRuhlLibre;
  src: url($fonts + 'FrankRuhlLibre-Bold.eot');
  src: url($fonts + 'FrankRuhlLibre-Bold.eot?#iefix') format('embedded-opentype'),
       url($fonts + 'FrankRuhlLibre-Bold.ttf') format('ttf'),
       url($fonts + 'FrankRuhlLibre-Bold.woff2') format('woff2'),
       url($fonts + 'FrankRuhlLibre-Bold.woff') format('woff'),
       url($fonts + 'FrankRuhlLibre-Bold.svg#FrankRuhlLibre-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: FrankRuhlLibre;
  src: url($fonts + 'FrankRuhlLibre-Black.eot');
  src: url($fonts + 'FrankRuhlLibre-Black.eot?#iefix') format('embedded-opentype'),
       url($fonts + 'FrankRuhlLibre-Black.ttf') format('ttf'),
       url($fonts + 'FrankRuhlLibre-Black.woff2') format('woff2'),
       url($fonts + 'FrankRuhlLibre-Black.woff') format('woff'),
       url($fonts + 'FrankRuhlLibre-Black.svg#FrankRuhlLibre-Black') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: FrankRuhlLibre;
  src: url($fonts + 'FrankRuhlLibre-Light.eot');
  src: url($fonts + 'FrankRuhlLibre-Light.eot?#iefix') format('embedded-opentype'),
       url($fonts + 'FrankRuhlLibre-Light.ttf') format('ttf'),
       url($fonts + 'FrankRuhlLibre-Light.woff2') format('woff2'),
       url($fonts + 'FrankRuhlLibre-Light.woff') format('woff'),
       url($fonts + 'FrankRuhlLibre-Light.svg#FrankRuhlLibre-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: FrankRuhlLibre;
  src: url($fonts + 'FrankRuhlLibre-Regular.eot');
  src: url($fonts + 'FrankRuhlLibre-Regular.eot?#iefix') format('embedded-opentype'),
       url($fonts + 'FrankRuhlLibre-Regular.ttf') format('ttf'),
       url($fonts + 'FrankRuhlLibre-Regular.woff2') format('woff2'),
       url($fonts + 'FrankRuhlLibre-Regular.woff') format('woff'),
       url($fonts + 'FrankRuhlLibre-Regular.svg#FrankRuhlLibre-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: FrankRuhlLibre;
  src: url($fonts + 'FrankRuhlLibre-Medium.eot');
  src: url($fonts + 'FrankRuhlLibre-Medium.eot?#iefix') format('embedded-opentype'),
       url($fonts + 'FrankRuhlLibre-Medium.ttf') format('ttf'),
       url($fonts + 'FrankRuhlLibre-Medium.woff2') format('woff2'),
       url($fonts + 'FrankRuhlLibre-Medium.woff') format('woff'),
       url($fonts + 'FrankRuhlLibre-Medium.svg#FrankRuhlLibre-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
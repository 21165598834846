#footer {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  background-color: $black;
  padding: 16px responsive(32, 248) 54px;
  color: $bg;
  position: relative;
  z-index: 1;
  img {
    display: block;
    max-width: 100%;
  }
  h3 {
    margin: 22px 0 18px;
  }
  a, address {
    display: block;
    font-size: 14px;
    margin: 0 0 16px;
  }
  .map {
    display: flex;
    align-items: center;
    img {
      margin: 0 16px 0 0;
    }
  }
  .share {
    display: flex;
    li {
      margin: 0 16px 0 0;
      a {
        display: block;
        width: 30px;
        height: 30px;
      }
    }
  }
  .support {
    li{
      &:first-of-type {
        display: flex;
        align-items: center;
        h3 {
          margin: 0 16px 0 0;
        }
        img {
          max-width: 70px;
        }
      }
    }
  }
}

@media(max-width: $tablet) { 
  #footer {
    grid-template-columns: 1fr;
  }
}
.books {
  padding-top: 188px;
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  h1 {
    font-size: 24px;
    font-weight: 700;
    margin: 0 0 80px;
  }
  .list {
    &-item {
      display: flex;
      margin: 0 0 100px;
      .image {
        border: 2px solid $black;
        margin: 0 106px 0 0;
        max-width: 309px;
        width: 100%;
        height: 452px;
      }
      .content {
        margin: 20px 0 0;
        h2 {
          font-size: responsive(44, 64);
          font-weight: 700;
          line-height: 1;
          margin: 0 0 5px;
        }
        h4 {
          font-size: responsive(20, 30);
          font-weight: 700;
          margin: 0 0 40px;
          max-width: 668px;
        }
        p {
          max-width: 668px;
          margin: 0 0 5px;
        }
        a {
          display: block;
          margin: 60px 0 0;
          color: $gray;
          text-align: right;
          font-weight: 700;
        }
      }
      &:nth-child(even) {
        justify-content: flex-end;
      }
    }
  }
}

@media(max-width: $tablet) {
  .books {
    padding-top: 116px;
    .list {
      &-item {
        flex-direction: column;
        .image {
          margin: 0 0 80px;
        }
      }
    }
  }
}
.page-template-page-a-casa {
  background-color: $black;
}
.house {
  background-color: $black;
  display: flex;
  padding: 72px responsive(24, 96) 0 responsive(32, 248);
  .sidebar {
    display: flex;
    flex-direction: column;
    padding: responsive(24, 70) 0;
    min-width: 35%;
    color: $bg;
    .tabs {
      display: flex;
      a {
        font-size: 14px;
        font-weight: 700;
        opacity: .25;
        &:first-of-type {
          margin: 0 56px 0 0;
        }
      }
    }
    .places {
      margin: responsive(24, 60) 0;
      li {
        font-size: responsive(44, 64);
        font-weight: 700;
        opacity: .25;
        cursor: pointer;
        transition: opacity .4s ease, color .4s ease;
        &.active {
          opacity: 1;
          color: $white;
        }
      }
    }
    .back {
      font-size: 14px;
      font-weight: 700;
    }
  }

  .image {
    padding: 18px 0;
    height: 90vh;
    width: 100%;
    svg {
      width: 100%;
      height: 100%;
      path {
        opacity: .25;
        transition: opacity .4s ease, color .4s ease;
      }
      .active {
        path {
          opacity: 1;
        }

        .lines {
          stroke: $white !important;
        }
        path:not(.lines) {
          fill: $white !important;
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .house {
    padding: 72px responsive(32, 248) 0 responsive(32, 248); 
    .sidebar {
      display: none;
    }
    .image {
      height: 100%;
    }
  }
}

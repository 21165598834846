body {
  margin: 0;
  font-family: FrankRuhlLibre;
  font-size: calc(0.125vw + 15.6px);
  font-weight: 400;
  background-color: #FBFAF6;
  color: #2E2E2E; }
  body * {
    box-sizing: border-box;
    margin: 0;
    padding: 0; }
  body h1, body h2, body h3, body h4, body h5, body h6 {
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit; }
  body p {
    line-height: 1.5; }
  body a {
    text-decoration: none;
    color: inherit; }
  body address {
    font-style: normal; }
  body button {
    background-color: transparent;
    border: none;
    color: inherit;
    cursor: pointer;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: 1;
    outline: 0;
    padding: 0; }
  body ul {
    margin: 0;
    padding: 0;
    list-style-type: none; }
  body input {
    padding: 0;
    margin: 0;
    background: transparent;
    border: none;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    outline: none; }

[hidden] {
  display: none; }

.wrap {
  padding: 72px calc(4.5vw + 9.6px) 0; }
  @media (max-width: 1000px) {
    .wrap {
      padding: 0 calc(4.5vw + 9.6px); } }

.current {
  opacity: 1 !important; }

@font-face {
  font-family: FrankRuhlLibre;
  src: url("../assets/fonts/FrankRuhlLibre-Bold.eot");
  src: url("../assets/fonts/FrankRuhlLibre-Bold.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/FrankRuhlLibre-Bold.ttf") format("ttf"), url("../assets/fonts/FrankRuhlLibre-Bold.woff2") format("woff2"), url("../assets/fonts/FrankRuhlLibre-Bold.woff") format("woff"), url("../assets/fonts/FrankRuhlLibre-Bold.svg#FrankRuhlLibre-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: FrankRuhlLibre;
  src: url("../assets/fonts/FrankRuhlLibre-Black.eot");
  src: url("../assets/fonts/FrankRuhlLibre-Black.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/FrankRuhlLibre-Black.ttf") format("ttf"), url("../assets/fonts/FrankRuhlLibre-Black.woff2") format("woff2"), url("../assets/fonts/FrankRuhlLibre-Black.woff") format("woff"), url("../assets/fonts/FrankRuhlLibre-Black.svg#FrankRuhlLibre-Black") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: FrankRuhlLibre;
  src: url("../assets/fonts/FrankRuhlLibre-Light.eot");
  src: url("../assets/fonts/FrankRuhlLibre-Light.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/FrankRuhlLibre-Light.ttf") format("ttf"), url("../assets/fonts/FrankRuhlLibre-Light.woff2") format("woff2"), url("../assets/fonts/FrankRuhlLibre-Light.woff") format("woff"), url("../assets/fonts/FrankRuhlLibre-Light.svg#FrankRuhlLibre-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: FrankRuhlLibre;
  src: url("../assets/fonts/FrankRuhlLibre-Regular.eot");
  src: url("../assets/fonts/FrankRuhlLibre-Regular.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/FrankRuhlLibre-Regular.ttf") format("ttf"), url("../assets/fonts/FrankRuhlLibre-Regular.woff2") format("woff2"), url("../assets/fonts/FrankRuhlLibre-Regular.woff") format("woff"), url("../assets/fonts/FrankRuhlLibre-Regular.svg#FrankRuhlLibre-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: FrankRuhlLibre;
  src: url("../assets/fonts/FrankRuhlLibre-Medium.eot");
  src: url("../assets/fonts/FrankRuhlLibre-Medium.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/FrankRuhlLibre-Medium.ttf") format("ttf"), url("../assets/fonts/FrankRuhlLibre-Medium.woff2") format("woff2"), url("../assets/fonts/FrankRuhlLibre-Medium.woff") format("woff"), url("../assets/fonts/FrankRuhlLibre-Medium.svg#FrankRuhlLibre-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

.header {
  background-color: #F9F4EA;
  padding: 16px calc(13.5vw + -11.2px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 2px solid #2E2E2E;
  position: fixed;
  width: 100%;
  z-index: 10; }
  .header-link {
    font-size: 30px;
    font-weight: 700; }
  .header .menu {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .header .menu li {
      margin: 0 24px; }
      .header .menu li a {
        font-weight: 700;
        opacity: 1;
        transition: opacity .4s ease; }
        .header .menu li a:hover {
          opacity: .6; }
  .header .search-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative; }
    .header .search-container .search {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      right: 0;
      width: 0;
      overflow: hidden;
      transition: width .4s ease; }
      .header .search-container .search [type="text"] {
        background: #F9F4EA;
        border-bottom: 2px solid #2E2E2E;
        padding: 5px 10px;
        margin: 0 20px 0 0;
        width: calc(100% - 50px); }
      .header .search-container .search.open {
        width: 60vw; }
    .header .search-container .btn-submit, .header .search-container .btn-search {
      background-image: url("../assets/search.svg");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      width: 30px;
      height: 30px; }
    .header .search-container .btn-search {
      opacity: 1;
      transition: opacity .4s ease; }
      .header .search-container .btn-search:hover {
        opacity: .6; }
  .header .btn-menu {
    width: 30px;
    height: 20px;
    position: relative; }
    .header .btn-menu:before, .header .btn-menu:after {
      content: '';
      position: absolute;
      height: 2px;
      width: 100%;
      left: 0;
      background: #2E2E2E;
      transition: transform .4s ease, top .4s ease, left .4s ease;
      transform: translate(0%, 0%) rotate(0deg); }
    .header .btn-menu:before {
      top: 0; }
    .header .btn-menu:after {
      top: calc(100% - 2px); }
    .header .btn-menu span {
      display: block;
      width: 100%;
      position: absolute;
      height: 2px;
      background: #2E2E2E;
      top: 50%;
      left: 50%;
      opacity: 1;
      transform: translate(-50%, -50%);
      transition: opacity .4s ease; }
    .header .btn-menu.active span {
      opacity: 0; }
    .header .btn-menu.active:before, .header .btn-menu.active:after {
      top: 50%;
      left: 50%; }
    .header .btn-menu.active:before {
      transform: translate(-50%, -50%) rotate(45deg); }
    .header .btn-menu.active:after {
      transform: translate(-50%, -50%) rotate(-45deg); }

.page:not(.home) .menu li {
  color: rgba(46, 46, 46, 0.25); }
  .page:not(.home) .menu li.current-menu-item {
    color: #2E2E2E; }

@media (max-width: 1000px) {
  .header {
    justify-content: flex-end;
    position: relative; }
    .header-link {
      position: absolute;
      left: calc(13.5vw + -11.2px); }
    .header .btn-menu {
      margin-left: 20px; }
      .header .btn-menu[hidden] {
        display: block; }
    .header > div {
      width: calc(100% + 4px);
      position: absolute;
      top: 64px;
      right: -2px; }
      .header > div .menu {
        background: #F9F4EA;
        overflow: hidden;
        flex-direction: column;
        align-items: flex-start;
        padding: 0 calc(13.5vw + -11.2px);
        border-right: 2px solid #2E2E2E;
        border-left: 2px solid #2E2E2E;
        max-height: 0px;
        transition: max-height .4s ease, padding .4s ease; }
        .header > div .menu.open {
          max-height: 300px;
          padding: 24px calc(13.5vw + -11.2px);
          border-bottom: 2px solid #2E2E2E; }
        .header > div .menu li {
          margin: 0 0 24px; }
    .header .search-container .search.open {
      width: 70vw; } }

#footer {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  background-color: #2E2E2E;
  padding: 16px calc(13.5vw + -11.2px) 54px;
  color: #FBFAF6;
  position: relative;
  z-index: 1; }
  #footer img {
    display: block;
    max-width: 100%; }
  #footer h3 {
    margin: 22px 0 18px; }
  #footer a, #footer address {
    display: block;
    font-size: 14px;
    margin: 0 0 16px; }
  #footer .map {
    display: flex;
    align-items: center; }
    #footer .map img {
      margin: 0 16px 0 0; }
  #footer .share {
    display: flex; }
    #footer .share li {
      margin: 0 16px 0 0; }
      #footer .share li a {
        display: block;
        width: 30px;
        height: 30px; }
  #footer .support li:first-of-type {
    display: flex;
    align-items: center; }
    #footer .support li:first-of-type h3 {
      margin: 0 16px 0 0; }
    #footer .support li:first-of-type img {
      max-width: 70px; }

@media (max-width: 1000px) {
  #footer {
    grid-template-columns: 1fr; } }

.home-hero {
  position: relative; }
  .home-hero .logo {
    position: absolute;
    z-index: 1;
    top: 120px;
    right: calc(13.5vw + -11.2px);
    width: calc(11.1875vw + 64.2px); }
    .home-hero .logo img {
      width: 100%; }
  .home-hero .legend {
    position: absolute;
    z-index: 1;
    bottom: 10px;
    right: calc(13.5vw + -11.2px); }
  .home-hero .link {
    position: absolute;
    z-index: 1;
    top: 50%;
    right: calc(1.125vw + -1.6px);
    transform: translateY(-50%) rotate(90deg);
    font-weight: 700; }

.home .hero-container {
  display: flex;
  position: relative;
  height: calc(100vh - 151px);
  overflow: hidden;
  border-right: 2px solid #2E2E2E;
  border-left: 2px solid #2E2E2E;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover; }

.home-menu {
  display: flex;
  justify-content: space-between;
  background-color: #F9F4EA;
  padding: 0 calc(13.5vw + -11.2px);
  border: 2px solid #2E2E2E; }
  .home-menu a {
    font-size: 20px;
    display: block;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    opacity: 1;
    transition: opacity .4s ease; }
    .home-menu a:nth-child(2) {
      justify-content: flex-end; }
      .home-menu a:nth-child(2) .icon-play {
        margin: 0 0 0 16px; }
    .home-menu a:hover {
      opacity: .8; }
      .home-menu a:hover .icon-play::after {
        transform: translate(-50%, -50%) scale(1.1); }
    .home-menu a .icon-play {
      display: block;
      height: 75px;
      width: 80px;
      position: relative;
      background-color: #FBFAF6;
      border-left: 2px solid #2E2E2E;
      border-right: 2px solid #2E2E2E; }
      .home-menu a .icon-play:before, .home-menu a .icon-play:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
      .home-menu a .icon-play:before {
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 10px solid #2E2E2E; }
      .home-menu a .icon-play:after {
        width: 36px;
        height: 36px;
        border-radius: 100%;
        border: 2px solid #2E2E2E;
        transform: translate(-50%, -50%) scale(1);
        transition: transform .4s ease; }

@media (max-width: 1000px) {
  .home-hero .logo {
    top: 60px; }
  .home-hero .link {
    right: calc(1.875vw + -16px); }
  .home-menu a {
    font-size: 18px; }
    .home-menu a .icon-play {
      width: 80px;
      height: 80px; }
      .home-menu a .icon-play:after {
        width: 40px;
        height: 40px; } }

@media (max-width: 700px) {
  .home-menu {
    flex-direction: column;
    padding: 0;
    border-right: none; }
    .home-menu a {
      width: 100%;
      padding-left: calc(13.5vw + -11.2px); }
      .home-menu a:nth-child(2) {
        justify-content: space-between;
        border-top: 2px solid #2E2E2E; } }

.project img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover; }

.project .banner {
  height: 600px;
  width: 100%;
  border: 2px solid #2E2E2E;
  margin: 0 0 184px; }

.project .list-item {
  display: flex;
  align-items: center;
  margin: 0 0 100px; }
  .project .list-item .image {
    border: 2px solid #2E2E2E;
    margin: 0 106px 0 0;
    max-width: 309px;
    width: 100%;
    height: 452px; }
  .project .list-item .content h2 {
    font-size: calc(1.25vw + 40px);
    font-weight: 700;
    line-height: 1;
    margin: 0 0 5px; }
  .project .list-item .content h4 {
    font-size: calc(0.625vw + 18px);
    font-weight: 700;
    margin: 0 0 40px;
    max-width: 668px; }
  .project .list-item .content p {
    max-width: 668px;
    margin: 0 0 5px; }
  .project .list-item:nth-child(even) {
    flex-direction: row-reverse; }
    .project .list-item:nth-child(even) .image {
      margin: 0 0 0 106px;
      max-width: 615px;
      width: 100%;
      height: 847px; }

@media (max-width: 1000px) {
  .project .list-item {
    flex-direction: column; }
    .project .list-item .image {
      margin: 0 0 80px; }
    .project .list-item:nth-child(even) {
      flex-direction: column; }
      .project .list-item:nth-child(even) .image {
        margin: 0 0 40px; } }

.people {
  padding-top: 188px; }
  .people img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .people h2 {
    font-size: calc(1.25vw + 32px);
    font-weight: 700;
    line-height: 1;
    margin: 0 0 40px; }
  .people .list-item {
    display: flex;
    margin: 0 0 100px; }
    .people .list-item .image {
      border: 2px solid #2E2E2E;
      margin: 0 106px 0 0;
      max-width: 309px;
      width: 100%;
      height: 452px; }
    .people .list-item .content {
      margin: 20px 0 0; }
      .people .list-item .content h2 {
        font-size: calc(1.25vw + 40px);
        font-weight: 700;
        line-height: 1;
        margin: 0 0 5px; }
      .people .list-item .content h4 {
        font-size: calc(0.625vw + 18px);
        font-weight: 700;
        margin: 0 0 40px;
        max-width: 668px; }
      .people .list-item .content p {
        max-width: 668px;
        margin: 0 0 5px; }
    .people .list-item:nth-child(even) {
      justify-content: flex-end; }
  .people .grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin: 0 0 calc(5vw + 24px);
    gap: 16px; }
    .people .grid-item {
      display: flex;
      flex-direction: column;
      margin: 0 0 30px; }
      .people .grid-item h3 {
        font-size: calc(0.75vw + 21.6px);
        font-weight: 700; }
      .people .grid-item h4 {
        font-weight: 700; }

@media (max-width: 1000px) {
  .people {
    padding-top: 116px; }
    .people .list-item {
      flex-direction: column; }
      .people .list-item .image {
        margin: 0 0 80px; }
    .people .grid {
      grid-template-columns: repeat(2, 1fr); } }

@media (max-width: 700px) {
  .people .grid {
    grid-template-columns: repeat(1, 1fr); }
    .people .grid-item {
      align-items: flex-start; } }

.books {
  padding-top: 188px; }
  .books img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .books h1 {
    font-size: 24px;
    font-weight: 700;
    margin: 0 0 80px; }
  .books .list-item {
    display: flex;
    margin: 0 0 100px; }
    .books .list-item .image {
      border: 2px solid #2E2E2E;
      margin: 0 106px 0 0;
      max-width: 309px;
      width: 100%;
      height: 452px; }
    .books .list-item .content {
      margin: 20px 0 0; }
      .books .list-item .content h2 {
        font-size: calc(1.25vw + 40px);
        font-weight: 700;
        line-height: 1;
        margin: 0 0 5px; }
      .books .list-item .content h4 {
        font-size: calc(0.625vw + 18px);
        font-weight: 700;
        margin: 0 0 40px;
        max-width: 668px; }
      .books .list-item .content p {
        max-width: 668px;
        margin: 0 0 5px; }
      .books .list-item .content a {
        display: block;
        margin: 60px 0 0;
        color: rgba(46, 46, 46, 0.25);
        text-align: right;
        font-weight: 700; }
    .books .list-item:nth-child(even) {
      justify-content: flex-end; }

@media (max-width: 1000px) {
  .books {
    padding-top: 116px; }
    .books .list-item {
      flex-direction: column; }
      .books .list-item .image {
        margin: 0 0 80px; } }

.page-template-page-a-casa {
  background-color: #2E2E2E; }

.house {
  background-color: #2E2E2E;
  display: flex;
  padding: 72px calc(4.5vw + 9.6px) 0 calc(13.5vw + -11.2px); }
  .house .sidebar {
    display: flex;
    flex-direction: column;
    padding: calc(2.875vw + 14.8px) 0;
    min-width: 35%;
    color: #FBFAF6; }
    .house .sidebar .tabs {
      display: flex; }
      .house .sidebar .tabs a {
        font-size: 14px;
        font-weight: 700;
        opacity: .25; }
        .house .sidebar .tabs a:first-of-type {
          margin: 0 56px 0 0; }
    .house .sidebar .places {
      margin: calc(2.25vw + 16.8px) 0; }
      .house .sidebar .places li {
        font-size: calc(1.25vw + 40px);
        font-weight: 700;
        opacity: .25;
        cursor: pointer;
        transition: opacity .4s ease, color .4s ease; }
        .house .sidebar .places li.active {
          opacity: 1;
          color: #fff; }
    .house .sidebar .back {
      font-size: 14px;
      font-weight: 700; }
  .house .image {
    padding: 18px 0;
    height: 90vh;
    width: 100%; }
    .house .image svg {
      width: 100%;
      height: 100%; }
      .house .image svg path {
        opacity: .25;
        transition: opacity .4s ease, color .4s ease; }
      .house .image svg .active path {
        opacity: 1; }
      .house .image svg .active .lines {
        stroke: #fff !important; }
      .house .image svg .active path:not(.lines) {
        fill: #fff !important; }

@media (max-width: 800px) {
  .house {
    padding: 72px calc(13.5vw + -11.2px) 0 calc(13.5vw + -11.2px); }
    .house .sidebar {
      display: none; }
    .house .image {
      height: 100%; } }

.history {
  padding: 72px 0 0; }
  .history img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .history .banner {
    height: 600px;
    width: 100%;
    border: 2px solid #2E2E2E;
    margin: 60px 0 140px; }
  .history .tabs {
    display: flex;
    background-color: #2E2E2E;
    padding: calc(2.875vw + 14.8px) calc(13.5vw + -11.2px); }
    .history .tabs a {
      font-size: 14px;
      font-weight: 700;
      color: #FBFAF6;
      opacity: .25; }
      .history .tabs a:first-of-type {
        margin: 0 56px 0 0; }
  .history .list-item {
    display: flex;
    margin: 0 0 100px; }
    .history .list-item .image {
      border: 2px solid #2E2E2E;
      margin: 0 106px 0 0;
      max-width: 309px;
      width: 100%;
      height: 452px; }
    .history .list-item .content {
      margin: 20px 0 0; }
      .history .list-item .content h2 {
        font-size: calc(1.25vw + 40px);
        font-weight: 700;
        line-height: 1;
        margin: 0 0 5px; }
      .history .list-item .content h4 {
        font-size: calc(0.625vw + 18px);
        font-weight: 700;
        margin: 0 0 40px;
        max-width: 668px; }
      .history .list-item .content p {
        max-width: 668px;
        margin: 0 0 5px; }
    .history .list-item:nth-child(even) {
      justify-content: flex-end; }

@media (max-width: 1000px) {
  .history {
    padding: 0; }
    .history .list-item {
      flex-direction: column; }
      .history .list-item .image {
        margin: 0 0 80px; } }

.collection {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 72px 0 0; }
  .collection .sidebar {
    padding: calc(2.875vw + 14.8px) calc(0.9375vw + 12px) calc(2.875vw + 14.8px) calc(13.5vw + -11.2px);
    background-image: url("../assets/bg.png");
    background-repeat: no-repeat;
    background-position: top left;
    background-size: 50%;
    border-left: 2px solid #2E2E2E;
    border-right: 2px solid #2E2E2E;
    border-bottom: 2px solid #2E2E2E; }
    .collection .sidebar h1 {
      line-height: 1;
      margin: 0 0 40px; }
    .collection .sidebar .tabs {
      display: flex; }
      .collection .sidebar .tabs a {
        font-size: 14px;
        font-weight: 700;
        opacity: .25; }
        .collection .sidebar .tabs a:first-of-type {
          margin: 0 56px 0 0; }
        .collection .sidebar .tabs a.active {
          opacity: 1; }
    .collection .sidebar .back {
      font-weight: 700; }
  .collection .container {
    height: calc(100vh - 72px); }
  .collection .list-view {
    overflow: hidden; }
    .collection .list-view.image-list {
      height: 100%;
      background-color: #fff;
      border-right: 2px solid #2E2E2E;
      border-bottom: 2px solid #2E2E2E; }
      .collection .list-view.image-list li {
        width: 100%;
        height: 100%;
        display: none; }
        .collection .list-view.image-list li img {
          object-fit: cover;
          width: 100%;
          height: 100%; }
    .collection .list-view .categories {
      margin: calc(2.875vw + 14.8px) 0; }
      .collection .list-view .categories li {
        cursor: pointer;
        font-size: calc(1.25vw + 40px);
        font-weight: 700;
        opacity: .25;
        transition: opacity .4s ease;
        width: fit-content; }
        .collection .list-view .categories li:hover {
          opacity: 1; }
  .collection[type="list"] .gallery-view {
    display: none; }
  .collection[type="gallery"] {
    grid-template-columns: 1fr 2fr; }
    .collection[type="gallery"] .container {
      overflow-y: scroll;
      height: calc(100vh - 72px); }
      .collection[type="gallery"] .container::-webkit-scrollbar {
        width: 6px; }
      .collection[type="gallery"] .container::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0); }
      .collection[type="gallery"] .container::-webkit-scrollbar-thumb {
        background-color: #2E2E2E;
        outline: none; }
    .collection[type="gallery"] .list-view {
      display: none; }
    .collection[type="gallery"] .sidebar {
      background-image: none;
      border: none;
      margin: 0 16px 0 0; }
      .collection[type="gallery"] .sidebar .search {
        position: relative;
        border: 2px solid #2E2E2E;
        margin: 0 0 16px; }
        .collection[type="gallery"] .sidebar .search input {
          width: 100%;
          height: 60px;
          padding: 10px 20px;
          font-size: 18px; }
        .collection[type="gallery"] .sidebar .search .btn-submit {
          position: absolute;
          right: 20px;
          top: 50%;
          transform: translateY(-50%);
          background-image: url("../assets/search.svg");
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          width: 40px;
          height: 40px; }
      .collection[type="gallery"] .sidebar h3 {
        margin: 15px 0;
        font-weight: 700; }
      .collection[type="gallery"] .sidebar label, .collection[type="gallery"] .sidebar h4 {
        font-size: 14px;
        font-weight: 700; }
      .collection[type="gallery"] .sidebar .item {
        margin: 8px 0 16px;
        display: flex;
        align-items: center;
        cursor: pointer; }
        .collection[type="gallery"] .sidebar .item label {
          cursor: pointer; }
        .collection[type="gallery"] .sidebar .item input[type=checkbox] {
          width: 24px;
          height: 24px;
          position: relative;
          margin: 0 16px 0 0;
          cursor: pointer; }
        .collection[type="gallery"] .sidebar .item input[type=checkbox]:before {
          content: "";
          display: block;
          position: absolute;
          width: 24px;
          height: 24px;
          top: 0;
          left: 0;
          border: 2px solid #2E2E2E;
          border-radius: 0;
          background-color: #FBFAF6;
          box-sizing: border-box; }
        .collection[type="gallery"] .sidebar .item input[type=checkbox]:checked:before {
          background-color: #2E2E2E; }
      .collection[type="gallery"] .sidebar .filtrar {
        border: 2px solid #2E2E2E;
        padding: 18px;
        overflow: hidden; }
        .collection[type="gallery"] .sidebar .filtrar h4 {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: space-between; }
        .collection[type="gallery"] .sidebar .filtrar .icon {
          display: block;
          width: 10px;
          height: 10px;
          position: relative; }
          .collection[type="gallery"] .sidebar .filtrar .icon:before {
            content: '';
            position: absolute;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 10px solid #2E2E2E;
            transform: scaleY(1);
            transition: transform .4s ease; }
        .collection[type="gallery"] .sidebar .filtrar .dropdown {
          max-height: 0;
          overflow: hidden;
          transition: max-height .4s ease; }
        .collection[type="gallery"] .sidebar .filtrar.open .dropdown {
          margin: 20px 0 0;
          max-height: 300px;
          overflow-y: scroll; }
          .collection[type="gallery"] .sidebar .filtrar.open .dropdown::-webkit-scrollbar {
            width: 5px; }
          .collection[type="gallery"] .sidebar .filtrar.open .dropdown::-webkit-scrollbar-track {
            box-shadow: none; }
          .collection[type="gallery"] .sidebar .filtrar.open .dropdown::-webkit-scrollbar-thumb {
            background-color: #2E2E2E;
            outline: none; }
        .collection[type="gallery"] .sidebar .filtrar.open .icon:before {
          transform: scaleY(-1); }
      .collection[type="gallery"] .sidebar .gallery-view {
        margin: 30px 0; }
    .collection[type="gallery"] .grid {
      display: grid;
      gap: 16px;
      grid-template-columns: repeat(3, 1fr);
      padding: calc(4.125vw + 34.8px) calc(13.5vw + -11.2px) calc(2.875vw + 14.8px) 16px; }
      .collection[type="gallery"] .grid li {
        background-color: #FBFAF6;
        position: relative;
        transition: transform .4s ease;
        transform: scale(1); }
        .collection[type="gallery"] .grid li:hover {
          z-index: 1;
          transform: scale(1.04); }
        .collection[type="gallery"] .grid li.hidden {
          display: none; }
        .collection[type="gallery"] .grid li.show {
          display: block !important; }
        .collection[type="gallery"] .grid li a {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          border: 2px solid #2E2E2E; }
        .collection[type="gallery"] .grid li .image {
          width: 100%;
          height: 250px;
          border-bottom: 2px solid #2E2E2E;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center; }
          .collection[type="gallery"] .grid li .image img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: contain; }
          .collection[type="gallery"] .grid li .image .placeholder {
            height: 60%; }
        .collection[type="gallery"] .grid li .content {
          padding: 24px 14px 10px; }
          .collection[type="gallery"] .grid li .content h3 {
            font-weight: 700;
            line-height: 1; }
          .collection[type="gallery"] .grid li .content p {
            font-size: 14px;
            font-weight: 700; }

.collection-single {
  padding: 72px 0 0;
  display: grid;
  grid-template-columns: 1fr 1fr; }
  .collection-single .image {
    border: 2px solid #2E2E2E;
    border-top: none;
    height: calc(100vh - 72px);
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .collection-single .image .zoom {
      background-position: 50% 50%;
      background-repeat: no-repeat;
      position: relative;
      width: 100%;
      overflow: hidden;
      cursor: zoom-in; }
      .collection-single .image .zoom img:hover {
        opacity: 0; }
      .collection-single .image .zoom img {
        transition: opacity .5s;
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain; }
  .collection-single .container {
    height: calc(100vh - 72px);
    padding: calc(2.875vw + 14.8px) calc(13.5vw + -11.2px) calc(2.875vw + 14.8px) calc(6.625vw + 2.8px);
    border-right: 2px solid #2E2E2E;
    border-bottom: 2px solid #2E2E2E;
    overflow-y: scroll; }
    .collection-single .container h1 {
      font-size: calc(1.25vw + 40px);
      line-height: 1.2;
      font-weight: 700;
      margin: 0 0 12px; }
    .collection-single .container h4, .collection-single .container h2 {
      font-size: 30px;
      font-weight: 700; }
    .collection-single .container h2 {
      margin: 24px 0; }
    .collection-single .container h3 {
      font-size: 26px;
      margin: 20px 0 14px;
      font-weight: 700; }
    .collection-single .container ul {
      list-style-type: disc; }
    .collection-single .container ul, .collection-single .container ol {
      margin: 0 0 20px;
      padding: 0 0 0 20px; }
    .collection-single .container p {
      margin: 0 0 10px; }
    .collection-single .container a {
      text-decoration: underline;
      cursor: pointer; }
    .collection-single .container blockquote {
      font-weight: 700;
      font-size: 32px;
      margin: 30px 0;
      position: relative;
      padding: 0 0 0 20px; }
      .collection-single .container blockquote::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 5px;
        height: 100%;
        background-color: #2E2E2E; }
    .collection-single .container .text {
      margin: 60px 0 0; }
    .collection-single .container .nav {
      margin: 80px 0 0;
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .collection-single .container .nav a {
        display: block;
        color: gray;
        font-weight: 700;
        opacity: 1; }
        .collection-single .container .nav a:hover {
          opacity: .6;
          transition: opacity .4s ease; }
      .collection-single .container .nav-next {
        text-align: right; }
      .collection-single .container .nav-title {
        opacity: .5;
        margin: 5px 0 0;
        font-weight: normal !important; }
      .collection-single .container .nav span {
        display: none; }
    .collection-single .container::-webkit-scrollbar {
      width: 6px;
      background-color: transparent; }
    .collection-single .container::-webkit-scrollbar-thumb {
      background-color: #2E2E2E;
      outline: none; }

@media (max-width: 1000px) {
  .collection {
    padding: 0; }
    .collection[type="gallery"] {
      grid-template-columns: 1fr; }
      .collection[type="gallery"] .sidebar {
        padding: calc(2.875vw + 14.8px) calc(13.5vw + -11.2px); }
        .collection[type="gallery"] .sidebar .sidebar-content {
          position: unset;
          width: 100%; }
      .collection[type="gallery"] .grid {
        grid-template-columns: repeat(2, 1fr);
        padding: calc(4.125vw + 34.8px) calc(13.5vw + -11.2px); }
  [type="list"] {
    grid-template-columns: 1fr; }
  .list-view.image-list {
    display: none; }
  .collection-single {
    grid-template-columns: 1fr;
    padding: calc(2.875vw + 14.8px) calc(13.5vw + -11.2px); }
    .collection-single .image {
      order: 2;
      border: none;
      height: 100%;
      overflow: unset; }
    .collection-single .container {
      border: none;
      height: 100%;
      overflow: unset;
      padding: calc(2.875vw + 14.8px) 0; } }

@media (max-width: 700px) {
  .collection[type="gallery"] .grid {
    grid-template-columns: repeat(1, 1fr); } }

.results {
  padding: 72px 0 0; }
  .results h1 {
    background-color: #2E2E2E;
    color: #fff;
    padding: calc(2.875vw + 14.8px) calc(13.5vw + -11.2px); }
  .results .grid {
    padding: calc(2.875vw + 14.8px) calc(13.5vw + -11.2px);
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px; }
    .results .grid article a {
      display: block; }
    .results .grid article .image {
      width: 100%;
      height: 250px;
      border: 2px solid #2E2E2E; }
      .results .grid article .image img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain; }
    .results .grid article .content {
      padding: 24px 14px 10px;
      border-bottom: 2px solid #2E2E2E;
      border-left: 2px solid #2E2E2E;
      border-right: 2px solid #2E2E2E; }
      .results .grid article .content h3 {
        font-weight: 700;
        line-height: 1; }
      .results .grid article .content p {
        font-size: 14px;
        font-weight: 700; }
  .results .notfound a {
    width: fit-content;
    padding: 8px 20px;
    font-size: 18px;
    display: block;
    margin: 20px 0 0;
    border: 2px solid #2E2E2E;
    opacity: 1;
    transition: opacity .4s ease; }
    .results .notfound a:hover {
      opacity: .25; }

.collection {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 72px 0 0;

  .sidebar {
    padding: responsive(24, 70) responsive(15, 30) responsive(24, 70) responsive(32, 248);
    background-image: url($img + 'bg.png');
    background-repeat: no-repeat;
    background-position: top left;
    background-size: 50%;
    border-left: 2px solid $black;
    border-right: 2px solid $black;
    border-bottom: 2px solid $black;

    h1 {
      line-height: 1;
      margin: 0 0 40px;
    }

    .tabs {
      display: flex;
      a {
        font-size: 14px;
        font-weight: 700;
        opacity: .25;
        &:first-of-type {
          margin: 0 56px 0 0;
        }
        &.active {
          opacity: 1;
        }
      }
    }

    .back {
      font-weight: 700;
    }
  }

  .container {
    height: calc(100vh - 72px);
  }

  .list-view {
    overflow: hidden;
    &.image-list {
      height: 100%;
      background-color: $white;
      border-right: 2px solid $black;
      border-bottom: 2px solid $black;
      li {
        width: 100%;
        height: 100%;
        display: none;
        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
    }
    .categories {
      margin: responsive(24, 70) 0;
      li {
        cursor: pointer;
        font-size: responsive(44, 64);
        font-weight: 700;
        opacity: .25;
        transition: opacity .4s ease;
        width: fit-content;
        &:hover {
          opacity: 1;
        }
      }
    }
  }

  &[type="list"] {
    .gallery-view {
      display: none;
    }
  }

  &[type="gallery"] {
    grid-template-columns: 1fr 2fr;
    .container {
      overflow-y: scroll;
      height: calc(100vh - 72px);
      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
      }
      &::-webkit-scrollbar-thumb {
        background-color: #2E2E2E;
        outline: none;
      }
    }
    .list-view {
      display: none;
    }
    .sidebar {
      background-image: none;
      border: none;
      margin: 0 16px 0 0;
      .search {
        position: relative;
        border: 2px solid $black;
        margin: 0 0 16px;
        input {
          width: 100%;
          height: 60px;
          padding: 10px 20px;
          font-size: 18px;
        }
        .btn-submit {
          position: absolute;
          right: 20px;
          top: 50%;
          transform: translateY(-50%);
          background-image: url($img + 'search.svg');
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          width: 40px;
          height: 40px;
        }
      }
      h3 {
        margin: 15px 0;
        font-weight: 700;
      }
      label, h4 {
        font-size: 14px;
        font-weight: 700;
      }
      .item {
        margin: 8px 0 16px;
        display: flex;
        align-items: center;
        cursor: pointer;
        label {
          cursor: pointer;
        }
        input[type=checkbox] {
          width: 24px;
          height: 24px;
          position: relative;
          margin: 0 16px 0 0;
          cursor: pointer;
        }
        input[type=checkbox]:before {
          content: "";
          display: block;
          position: absolute;
          width: 24px;
          height: 24px;
          top: 0;
          left: 0;
          border: 2px solid $black;
          border-radius: 0;
          background-color: $bg;
          box-sizing: border-box;
        }
        input[type=checkbox]:checked:before {
          background-color: $black;
        }
      }
      .filtrar {
        border: 2px solid $black;
        padding: 18px;
        overflow: hidden;
        h4 {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .icon {
          display: block;
          width: 10px;
          height: 10px;
          position: relative;
          &:before {
            content: '';
            position: absolute;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 10px solid $black;
            transform: scaleY(1);
            transition: transform .4s ease;
          }
        }
        .dropdown {
          max-height: 0;
          overflow: hidden;
          transition: max-height .4s ease;
        }
        &.open {
          .dropdown {
            margin: 20px 0 0;
            max-height: 300px;
            overflow-y: scroll;
            &::-webkit-scrollbar {
              width: 5px;
            }
            &::-webkit-scrollbar-track {
              box-shadow: none;
            }
            &::-webkit-scrollbar-thumb {
              background-color: $black;
              outline: none;
            }
          }
          .icon {
            &:before {
              transform: scaleY(-1);
            }
          }
        }
      }
      .gallery-view {
        margin: 30px 0;
      }
    }
    .grid {
      display: grid;
      gap: 16px;
      grid-template-columns: repeat(3,1fr);
      padding: responsive(48, 114) responsive(32, 248) responsive(24, 70) 16px;
      li {
        background-color: #FBFAF6;
        position: relative;
        transition: transform .4s ease;
        transform: scale(1);
        &:hover {
          z-index: 1;
          transform: scale(1.04);
        }
        &.hidden {
          display: none;
        }

        &.show {
          display: block !important;
        }
        a {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          border: 2px solid $black;
        }
        .image {
          width: 100%;
          height: 250px;
          border-bottom: 2px solid $black;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
          .placeholder{
            height: 60%;
          }
        }
        .content {
          padding: 24px 14px 10px;
          h3 {
            font-weight: 700;
            line-height: 1;
          }
          p {
            font-size: 14px;
            font-weight: 700;
          }
        }
      }
    }
  }
}

.collection-single {
  padding: 72px 0 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  .image {
    border: 2px solid $black;
    border-top: none;
    height: calc(100vh - 72px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    .zoom {
      & img:hover {
        opacity: 0;
      }
      img {
        transition: opacity .5s;
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      background-position: 50% 50%;
      background-repeat: no-repeat;
      position: relative;
      width: 100%;
      overflow: hidden;
      cursor: zoom-in;
    }
  }
  .container {
    height: calc(100vh - 72px);
    padding: responsive(24, 70) responsive(32, 248) responsive(24, 70) responsive(24, 130);
    border-right: 2px solid $black;
    border-bottom: 2px solid $black;
    overflow-y: scroll;
    h1 {
      font-size: responsive(44, 64);
      line-height: 1.2;
      font-weight: 700;
      margin: 0 0 12px;
    }
    h4, h2 {
      font-size: 30px;
      font-weight: 700;
    }
    h2 {
      margin: 24px 0;
    }
    h3 {
      font-size: 26px;
      margin: 20px 0 14px;
      font-weight: 700;
    }
    ul{
      list-style-type: disc;
    }
    ul, ol {
      margin: 0 0 20px;
      padding: 0 0 0 20px;
    }
    p {
      margin: 0 0 10px;
    }
    a {
      text-decoration: underline;
      cursor: pointer;
    }
    blockquote {
      font-weight: 700;
      font-size: 32px;
      margin: 30px 0;
      position: relative;
      padding: 0 0 0 20px;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 5px;
        height: 100%;
        background-color: $black;
      }
    }
    .text {
      margin: 60px 0 0;
    }
    .nav {
      margin: 80px 0 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      a {
        display: block;
        color: gray;
        font-weight: 700;
        opacity: 1;
        &:hover {
          opacity: .6;
          transition: opacity .4s ease;
        }
      }
      &-next {
        text-align: right;
      }
      &-title {
        opacity: .5;
        margin: 5px 0 0;
        font-weight: normal !important;
      }
      span {
        display: none;
      }
    }
    &::-webkit-scrollbar {
      width: 6px;
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $black;
      outline: none;
    }
  }
}

@media(max-width: $tablet) {
  .collection {
    padding: 0;
    &[type="gallery"] {
      grid-template-columns: 1fr;
      .sidebar {
        padding: responsive(24, 70) responsive(32, 248);
        .sidebar-content {
          position: unset;
          width: 100%;
        }
      }
      .grid {
        grid-template-columns: repeat(2, 1fr);
        padding: responsive(48, 114) responsive(32, 248);
      }
    }
  }
  &[type="list"] {
    grid-template-columns: 1fr;
  }
  .list-view.image-list {
    display: none;
  }

  .collection-single {
    grid-template-columns: 1fr;
    padding: responsive(24, 70) responsive(32, 248);
    .image {
      order: 2;
      border: none;
      height: 100%;
      overflow: unset;
    }
    .container {
      border: none;
      height: 100%;
      overflow: unset;
      padding: responsive(24, 70) 0;
    }
  }
}

@media(max-width: $mobile) {
  .collection {
    &[type="gallery"] {
      .grid {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
}
.results {
  padding: 72px 0 0;
  h1 {
    background-color: #2E2E2E;
    color: $white;
    padding: responsive(24, 70) responsive(32, 248);
  }
  .grid {
    padding: responsive(24, 70) responsive(32, 248);
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;

    article {
      a {
        display: block;
      }
      .image {
        width: 100%;
        height: 250px;
        border: 2px solid $black;
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .content {
        padding: 24px 14px 10px;
        border-bottom: 2px solid $black;
        border-left: 2px solid $black;
        border-right: 2px solid $black;
        h3 {
          font-weight: 700;
          line-height: 1;
        }
        p {
          font-size: 14px;
          font-weight: 700;
        }
      }
    }
  }
  .notfound{
    a {
      width: fit-content;
      padding: 8px 20px;
      font-size: 18px;
      display: block;
      margin: 20px 0 0;
      border: 2px solid $black;
      opacity: 1;
      transition: opacity .4s ease;
      &:hover {
        opacity: .25;
      }
    }
  }
}

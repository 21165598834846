.history {
  padding: 72px 0 0;
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .banner {
    height: 600px;
    width: 100%;
    border: 2px solid $black;
    margin: 60px 0 140px;
  }
  .tabs {
    display: flex;
    background-color: $black;
    padding: responsive(24, 70) responsive(32, 248);
    a {
      font-size: 14px;
      font-weight: 700;
      color: $bg;
      opacity: .25;
      &:first-of-type {
        margin: 0 56px 0 0;
      }
    }
  }
  .list-item {
    display: flex;
    margin: 0 0 100px;
    .image {
      border: 2px solid $black;
      margin: 0 106px 0 0;
      max-width: 309px;
      width: 100%;
      height: 452px;
    }
    .content {
      margin: 20px 0 0;
      h2 {
        font-size: responsive(44, 64);
        font-weight: 700;
        line-height: 1;
        margin: 0 0 5px;
      }
      h4 {
        font-size: responsive(20, 30);
        font-weight: 700;
        margin: 0 0 40px;
        max-width: 668px;
      }
      p {
        max-width: 668px;
        margin: 0 0 5px;
      }
    }
    &:nth-child(even) {
      justify-content: flex-end;
    }
  }
}

@media(max-width: $tablet) {
  .history {
    padding: 0;
    .list {
      &-item {
        flex-direction: column;
        .image {
          margin: 0 0 80px;
        }
      }
    }
  }
}
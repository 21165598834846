.project {
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .banner {
    height: 600px;
    width: 100%;
    border: 2px solid $black;
    margin: 0 0 184px;
  }

  .list-item {
    display: flex;
    align-items: center;
    margin: 0 0 100px;
    .image {
      border: 2px solid $black;
      margin: 0 106px 0 0;
      max-width: 309px;
      width: 100%;
      height: 452px;
    }
    .content {
      h2 {
        font-size: responsive(44, 64);
        font-weight: 700;
        line-height: 1;
        margin: 0 0 5px;
      }
      h4 {
        font-size: responsive(20, 30);
        font-weight: 700;
        margin: 0 0 40px;
        max-width: 668px;
      }
      p {
        max-width: 668px;
        margin: 0 0 5px;
      }
    }
    &:nth-child(even) {
      flex-direction: row-reverse;
      .image {
        margin: 0 0 0 106px;
        max-width: 615px;
        width: 100%;
        height: 847px;
      }
    }
  }
}

@media(max-width: $tablet) {
  .project {
    .list {
      &-item {
        flex-direction: column;
        .image {
          margin: 0 0 80px;
        }
        &:nth-child(even) {
          flex-direction: column;
          .image {
            margin: 0 0 40px;
          }
        }
      }
    }
  }
}
.people {
  padding-top: 188px;
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  h2 {
    font-size: responsive(36, 56);
    font-weight: 700;
    line-height: 1;
    margin: 0 0 40px;
  }

  .list {
    &-item {
      display: flex;
      margin: 0 0 100px;
      .image {
        border: 2px solid $black;
        margin: 0 106px 0 0;
        max-width: 309px;
        width: 100%;
        height: 452px;
      }
      .content {
        margin: 20px 0 0;
        h2 {
          font-size: responsive(44, 64);
          font-weight: 700;
          line-height: 1;
          margin: 0 0 5px;
        }
        h4 {
          font-size: responsive(20, 30);
          font-weight: 700;
          margin: 0 0 40px;
          max-width: 668px;
        }
        p {
          max-width: 668px;
          margin: 0 0 5px;
        }
      }
      &:nth-child(even) {
        justify-content: flex-end;
      }
    }
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin: 0 0 responsive(40, 120);
    gap: 16px;
    &-item {
      display: flex;
      flex-direction: column;
      margin: 0 0 30px;
      h3 {
        font-size: responsive(24, 36);
        font-weight: 700;
      }
      h4 {
        font-weight: 700;
      }
    }
  }
}

@media(max-width: $tablet) {
  .people {
    padding-top: 116px;
    .list {
      &-item {
        flex-direction: column;
        .image {
          margin: 0 0 80px;
        }
      }
    }
    .grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media(max-width: $mobile) {
  .people {
    .grid {
      grid-template-columns: repeat(1, 1fr);
      &-item {
        align-items: flex-start;
      }
    }
  }
}
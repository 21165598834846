body {
  margin: 0;
  font-family: FrankRuhlLibre;
  font-size: responsive(16, 18);
  font-weight: 400;
  background-color: $bg;
  color: $black;

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
  }

  p {
    line-height: 1.5;
  }
  
  a {
    text-decoration: none;
    color: inherit;
  }

  address {
    font-style: normal;
  }

  button {
    background-color: transparent;
    border: none;
    color: inherit;
    cursor: pointer;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: 1;
    outline: 0;
    padding: 0;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  input {
    padding: 0;
    margin: 0;
    background: transparent;
    border: none;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    outline: none;
  }
}
